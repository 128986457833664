import { useContext } from 'react';
import { ModalLink } from './ModalLink';
import { SiteContext } from '../../contexts/Site/SiteContext';

const showsStub = [
  {
    date: '5/8',
    venue: 'Bar Freda',
    ticketLink: undefined,
    city: 'Brooklyn, NY',
  },
  {
    date: '7/6',
    venue: 'Trans Pecos',
  },
];
export const ShowsInfo = () => {
  const {
    colorTheme: { accentColor },
  } = useContext(SiteContext);
  return (
    <>
      <p
        style={{
          marginBottom: '5px',
        }}
      >
        {' '}
        Upcoming Shows:{' '}
      </p>
      {showsStub.map((show) => {
        const { date, venue, ticketLink } = show;
        return (
          <div
            className="shows-info"
            key={date + Math.floor(Math.random() * 12345)}
          >
            <div>
              <p style={{ color: accentColor }}>
                &#x279F;{' '}
                <ModalLink
                  href={ticketLink}
                  color={accentColor}
                  text={`${date} @ ${venue}`}
                  includeIcon={false}
                />
              </p>
            </div>
          </div>
        );
      })}
    </>
  );
};
