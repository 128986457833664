import { useRef, useState, useCallback, useContext, useEffect } from 'react';
import WaveForm from './WaveForm';
import { Icon } from '../icons/Icon';
import './AudioPlayer.css';
import { SiteContext } from '../../contexts/Site/SiteContext';
import { AudioInfo } from './AudioInfo';
import { TorrentContext } from '../../contexts/Torrent';

export default function AudioPlayer({
  width,
  height,
  audioUrl,
  songName = '2064',
}) {
  const {
    isMobileView,
    colorTheme: { accentColor, fillColor },
    isWebTorrentExperiment,
  } = useContext(SiteContext);

  const { isTorrenting, initTorrent, isSeeding } = useContext(TorrentContext);
  const [analyzerData, setAnalyzerData] = useState(null);
  const audioElmRef = useRef(null);
  const sourceRef = useRef(null);

  const audioCtxRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const audioAnalyzer = useCallback(() => {
    if (!audioCtxRef.current) {
      audioCtxRef.current = new (window.AudioContext ||
        window.webkitAudioContext)();
    }

    if (!sourceRef.current) {
      // Create a new MediaElementSourceNode only if it doesn't already exist
      sourceRef.current = audioCtxRef.current.createMediaElementSource(
        audioElmRef.current
      );
      sourceRef.current.connect(audioCtxRef.current.destination);
    }

    if (!analyzerData) {
      const analyzer = audioCtxRef.current.createAnalyser();
      analyzer.fftSize = 2048;
      const bufferLength = analyzer.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      sourceRef.current.connect(analyzer); // Connect the source to the analyzer
      setAnalyzerData({ analyzer, bufferLength, dataArray });
    }
  }, [analyzerData]);

  const toggleAudio = useCallback(() => {
    // in webtorrent mode
    // if not torrenting and not seeding
    // button should init torrent
    if (isWebTorrentExperiment) {
      if (!isTorrenting) {
        initTorrent();
        return;
      }
      if (!audioUrl) return;
    }

    if (!audioUrl) return;
    if (audioElmRef.current) {
      if (audioElmRef.current.paused) {
        if (!audioCtxRef.current) {
          audioCtxRef.current = new (window.AudioContext ||
            window.webkitAudioContext)();
        }
        audioAnalyzer();
        audioElmRef.current.play();
        setIsPlaying(true);
        setIsHovering(false);
      } else {
        audioElmRef.current.pause();
        setIsPlaying(false);
      }
    }
  }, [audioElmRef, audioUrl, isWebTorrentExperiment, isTorrenting]);

  const [hasPlayed, setHasPlayed] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    // auto play once torrent has finished downloading
    if (!isWebTorrentExperiment) return;

    if (hasPlayed) return;

    if (audioElmRef.current && isSeeding && isTorrenting) {
      if (!audioCtxRef.current) {
        audioCtxRef.current = new (window.AudioContext ||
          window.webkitAudioContext)();
      }
      audioAnalyzer();
      audioElmRef.current.play();
      setIsPlaying(true);
      setIsHovering(false);
      setHasPlayed(true);
    }
  }, [
    isWebTorrentExperiment,
    isSeeding,
    isTorrenting,
    setIsHovering,
    setIsPlaying,
    hasPlayed,
    setHasPlayed,
    audioElmRef,
  ]);

  const onMouseEnter = () => {
    if (isMobileView || isPlaying) return;
    setIsHovering(true);
  };

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === ' ') {
        toggleAudio();
      }
    },
    [toggleAudio]
  );

  useEffect(() => {
    window.addEventListener('keypress', handleKeyPress);
    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  });

  const onMouseLeave = () => {
    if (isMobileView) return;
    setIsHovering(false);
  };

  const iconColor = isHovering && !isMobileView ? fillColor : accentColor;
  const iconfillColor = isHovering ? accentColor : fillColor;
  const iconBorderColor = isHovering ? accentColor : fillColor;

  return (
    <>
      {!isPlaying ? (
        <div
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          className="media-button"
          onClick={toggleAudio}
        >
          <Icon
            type={isSeeding || !isTorrenting ? 'play' : 'loading'}
            color={iconColor}
            fillColor={iconfillColor}
            borderColor={iconBorderColor}
          />
        </div>
      ) : (
        <div className="media-button" onClick={toggleAudio}>
          <Icon
            type="pause"
            color={accentColor}
            fillColor={fillColor}
            borderColor={fillColor}
          />
        </div>
      )}
      <audio
        style={{ display: 'none' }}
        ref={audioElmRef}
        src={audioUrl}
        controls
      />
      {analyzerData && (
        <WaveForm
          analyzerData={analyzerData}
          width={width}
          height={height}
          fillColor={accentColor}
        />
      )}
      <AudioInfo
        isPlaying={isPlaying}
        songName={songName}
        onClick={toggleAudio}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        textColor={iconColor}
        fillColor={iconfillColor}
      />
    </>
  );
}
