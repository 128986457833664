import './Loading.css';

export const Loading = ({ size = 25, color = '#000' }) => {
  return (
    <span
      className="loading-spinner"
      style={{
        width: `${size}px`,
        height: `${size}px`,
        borderColor: `${color} ${color} ${color} transparent`,
      }}
    ></span>
  );
};
