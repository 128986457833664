import { useContext } from 'react';
import { Navigation } from '../navigation';
import { NettLogo } from '../nett-logo';
import './Header.css';
import { SiteContext } from '../../contexts/Site/SiteContext';

export const Header = () => {
  const { isHeaderVisible, setIsHeaderVisible, isMobileView, colorTheme } =
    useContext(SiteContext);

  const onMouseEnter = () => {
    setIsHeaderVisible(true);
  };
  const onMouseLeave = () => {
    if (!isMobileView) {
      setIsHeaderVisible(false);
    }
  };

  const style = {
    background: isHeaderVisible ? colorTheme.fillColor : 'transparent',
    color: isHeaderVisible ? colorTheme.textColor : colorTheme,
  };

  return (
    <header
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={style}
      // className={isHeaderVisible ? "visible" : ""}
    >
      <NettLogo />
      <Navigation />
    </header>
  );
};
