import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  darkColorTheme,
  darkGreenTheme,
  defaultAudioUrl,
  lightColorTheme,
  menus,
  mobileBreakpoint,
  nettBackgroundSrc,
} from './constants';
import { SiteContext } from './SiteContext';
import { TorrentContext } from '../Torrent';

const SiteContextProvider = ({ children }) => {
  const params = new URLSearchParams(window.location.search);
  const isTorrentParam = params.get('torrent');
  const [isWebTorrentExperiment] = useState(isTorrentParam);
  const [currentMenu, setCurrentMenu] = useState(
    isWebTorrentExperiment ? null : menus.info
  );
  const [hoveredMenu, setHoveredMenu] = useState(null);
  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth < mobileBreakpoint
  );

  const [siteWidth, setSiteWidth] = useState(window.innerWidth);

  const { audioUrl, isSeeding } = useContext(TorrentContext);
  useEffect(() => {
    const handleResize = () => {
      // setWindowWidth(window.innerWidth);
      setIsMobileView(window.innerWidth < mobileBreakpoint);
      setSiteWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  const [isHeaderVisible, setIsHeaderVisible] = useState(isMobileView);

  const colorTheme = useMemo(
    () =>
      isWebTorrentExperiment
        ? isSeeding
          ? darkGreenTheme
          : darkColorTheme
        : lightColorTheme,
    [isWebTorrentExperiment, isSeeding]
  );

  return (
    <SiteContext.Provider
      value={{
        colorTheme: colorTheme,
        setColorTheme: () => {},
        backgroundImageSrc: nettBackgroundSrc,
        currentMenu: currentMenu,
        setCurrentMenu: setCurrentMenu,
        hoveredMenu: hoveredMenu,
        setHoveredMenu: setHoveredMenu,
        isMobileView: isMobileView,
        isHeaderVisible,
        setIsHeaderVisible,
        siteWidth,
        isWebTorrentExperiment,
        currentAudioUrl: isWebTorrentExperiment ? audioUrl : defaultAudioUrl,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};

export default SiteContextProvider;
