import { menus } from '../../contexts/Site';
import { Contact } from './Contact';
import './Icons.css';
import { Info } from './Info';
import { Loading } from './Loading';
import { Pause } from './Pause';
import { Play } from './Play';
import { Shows } from './Show';

export const Icon = ({ color, type, fillColor, borderColor }) => {
  const style = {
    borderColor: borderColor,
    background: fillColor,
  };
  const component = () => {
    switch (type) {
      case menus.contact:
        return <Contact color={color} />;
      case menus.info:
        return <Info color={color} />;
      case menus.shows:
        return <Shows color={color} />;
      case 'play':
        return <Play color={color} />;
      case 'pause':
        return <Pause color={color} />;
      case 'loading':
        return <Loading color={color} />;
      default:
        return null;
    }
  };
  return (
    <div className="icon" style={style}>
      {component()}
    </div>
  );
};
