import { SiteContext } from '../../contexts/Site/SiteContext';
import { InfoModal } from '../info-modal';
import { LazyImage } from '../lazy-image';
import './Background.css';

import background from '../../images/nett-background.png';
import ancientSlate from '../../images/ancient-slate.png';
import nettorentImage from '../../images/nettorrent.png';
import netttorrentMobileImage from '../../images/nettorrent-mobile.png';
import { useContext, useEffect, useRef, useState } from 'react';
import { TorrentInfoText } from './TorrentInfoText';
import { TorrentContext } from '../../contexts/Torrent';

const randomIndex = Math.floor(Math.random() * 2);
export const Background = () => {
  const backgroundRef = useRef(null);

  const { isWebTorrentExperiment, isMobileView } = useContext(SiteContext);
 const {isTorrenting} = useContext(TorrentContext)
  const [containerHeight, setcontainerHeight] = useState(0);
  const [containerWidth, setcontainerWidth] = useState(0);

  const backgrounds = [background, ancientSlate];

  useEffect(() => {
    const updateSize = () => {
      const backgroundDiv = backgroundRef.current;
      const { height, width } = backgroundDiv.getBoundingClientRect();
      setcontainerHeight(height);
      setcontainerWidth(width);
    };

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, [setcontainerHeight, setcontainerWidth, backgroundRef]);



  const lazyImageStyle = isWebTorrentExperiment && {
    transform: isTorrenting ? "rotateX(32deg)" : "rotateX(0deg)",
    transition: "transform 2s",
    zIndex: 0
  }


  return (
    <div className="background" ref={backgroundRef}>
      <InfoModal />

      {isWebTorrentExperiment && <TorrentInfoText />}
      <LazyImage
      style={lazyImageStyle}
        src={
          isWebTorrentExperiment
            ? isMobileView
              ? netttorrentMobileImage
              : nettorentImage
            : backgrounds[randomIndex]
        }
        height={containerHeight}
        width={containerWidth}
      />
    </div>
  );
};
