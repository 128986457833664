import { useContext, useMemo } from 'react';
import { SiteContext } from '../../contexts/Site/SiteContext';
import { TorrentContext } from '../../contexts/Torrent';

export const AudioInfo = ({
  songName,
  isPlaying,
  textColor = 'red',
  fillColor = 'white',
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  const { isMobileView, isWebTorrentExperiment } = useContext(SiteContext);
  const { isTorrenting, isSeeding } = useContext(TorrentContext);

  const style = {
    width: 'auto',
    transition: 'width 1s',
    color: textColor,
    backgroundColor: fillColor,
  };
  const audioText = useMemo(() => {
    if (isWebTorrentExperiment) {
      if (isTorrenting && !isSeeding) {
        return (
          <span>Downloading {`\u201C${'Spare Parts'}\u201D`} by Nett</span>
        );
      }
      if (isPlaying) {
        return (
          <span>Listening to {`\u201C${'Spare Parts'}\u201D`} by Nett</span>
        );
      }
      return <span>Listen to {`\u201C${'Spare Parts'}\u201D`} by Nett</span>;
    }
    return isPlaying ? (
      <span>Listening to {`\u201C${songName}\u201D`} by Nett</span>
    ) : (
      <span>Listen to Nett</span>
    );
  }, [isPlaying, isTorrenting, isSeeding, songName, isWebTorrentExperiment]);

  return (
    <div
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={
        isMobileView ? 'audio-info-container mobile' : 'audio-info-container'
      }
      style={style}
    >
      {audioText}
    </div>
  );
};
