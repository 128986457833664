import { useCallback } from 'react';
import './InfoModal.css';
export const ModalLink = ({
  onClick,
  color,
  href,
  text,
  icon = 'arrow',
  includeIcon = true,
}) => {
  const linkStyle = {
    color,
  };

  const iconCharacter = () => {
    switch (icon) {
      case 'arrow':
        return 'V';
      case 'mail':
        return 'm';
      default:
        return 'V';
    }
  };

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    },
    [onClick]
  );

  return (
    <span className="info-modal-link-container">
      <a
        className="info-modal-link"
        style={linkStyle}
        href={href}
        target="_blank"
        onClick={handleClick}
      >
        {text}
      </a>
      {includeIcon && (
        <span className="info-modal-link-icon" style={{ color }}>
          {iconCharacter()}
        </span>
      )}
    </span>
  );
};
