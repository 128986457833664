import { useCallback, useEffect, useState } from 'react';
import WebTorrent from 'webtorrent/dist/webtorrent.min.js'; // Add a declaration file for this module
import { defaultMagnetLink } from './constants';
import { TorrentContext } from './TorrentContext';
const client = new WebTorrent();

function TorrentContextProvider({ children }) {
  const [currentTorrent, setCurrentTorrent] = useState(null);
  const [timeElapsedSeconds, setTimeElapsedSeconds] = useState(0);
  const [isTorrenting, setIsTorrenting] = useState(false);
  const [isSeeding, setIsSeeding] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [numPeers, setNumPeers] = useState(null);
  const [isError, setIsError] = useState(false);

  const initTorrent = useCallback(() => {
    if (client.torrents.length > 0) return;
    setIsTorrenting(true);
    setInterval(() => {
      setTimeElapsedSeconds((p) => p + 1);
    }, 1000);
    client.add(defaultMagnetLink, (torrent) => {
      setCurrentTorrent(torrent);
      setNumPeers(torrent.numPeers);
      torrent.on('error', function (err) {
        console.error('Torrent error:', err);
        setIsError(true);
      });

      torrent.on('warning', function (err) {
        console.warn('Torrent warning:', err);
      });

      torrent.on('download', () => {
        setNumPeers(torrent.numPeers);
        // console.log('Progress: ' + (torrent.progress * 100).toFixed(1) + '%');
      });

      // Add this to monitor upload/seeding
      torrent.on('upload', () => {
        const uploadSpeed = (torrent.uploadSpeed / 1024 / 1024).toFixed(2); // MB/s
        const ratio = (torrent.uploaded / torrent.downloaded).toFixed(2);
        console.log(
          `Seeding - Upload speed: ${uploadSpeed} MB/s - Ratio: ${ratio}`
        );
      });

      torrent.on('done', () => {
        const file = torrent.files[0];
        console.log(torrent);
        file.blob().then((blob) => {
          const url = URL.createObjectURL(blob); // Create a Blob URL
          setAudioUrl(url); // Save the Blob URL to state
          console.log('Blob URL created:', url);
        });
      });

      torrent.on('wire', (wire) => {
        setNumPeers(torrent.numPeers);
        wire.on('close', () => {
          setNumPeers(torrent.numPeers);
        });
      });

      setInterval(() => {
        setNumPeers(torrent.numPeers);
      }, 1000);
    });
  }, [
    setIsTorrenting,
    setAudioUrl,
    setCurrentTorrent,
    setNumPeers,
    setTimeElapsedSeconds,
  ]);

  useEffect(() => {
    if (audioUrl) {
      setIsSeeding(true);
    } else {
      setIsSeeding(false);
    }
  }, [audioUrl]);

  return (
    <TorrentContext.Provider
      value={{
        initTorrent,
        currentTorrent,
        audioUrl,
        isSeeding,
        isTorrenting,
        numPeers,
        timeElapsedSeconds,
        isError,
      }}
    >
      {children}
    </TorrentContext.Provider>
  );
}

export default TorrentContextProvider;
