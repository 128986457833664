import { useCallback, useContext, useMemo } from 'react';
import { SiteContext } from '../../contexts/Site/SiteContext';
import './NettLogo.css';
export const NettLogo = () => {
  const {
    colorTheme: { textColor, accentColor, fillColor },
    setCurrentMenu,
    isHeaderVisible,
    isWebTorrentExperiment,
  } = useContext(SiteContext);

  const mainTextStyle = {
    color: isWebTorrentExperiment
      ? isHeaderVisible
        ? textColor
        : fillColor
      : textColor,
  };

  const subTextStyle = {
    color: accentColor,
  };

  const onClick = useCallback(() => {
    setCurrentMenu(null);
  }, [setCurrentMenu]);

  const isSubTextVisible = useMemo(
    () => (isWebTorrentExperiment ? true : isHeaderVisible),
    [isWebTorrentExperiment, isHeaderVisible]
  );
  return (
    <div
      className={
        isHeaderVisible
          ? 'nett-logo-container visible'
          : 'nett-logo-container visible'
      }
      onClick={onClick}
    >
      <span style={mainTextStyle} className="nett-logo-main-text">
        nett
      </span>
      {isSubTextVisible && (
        <span style={subTextStyle} className="nett-logo-sub-text">
          (nyc)
        </span>
      )}
    </div>
  );
};
