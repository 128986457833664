import { useCallback, useEffect, useRef, useState } from 'react';
import './LazyImage.css';
export const LazyImage = ({ src, width, height, style }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const onLoaded = useCallback(() => {
    setIsLoaded(true);
  });
  // todo: implement lazy image loading
  return (
    <img
      className={isLoaded ? 'lazy-image loaded' : 'lazy-image'}
      src={src}
      width={width}
      height={height}
      onLoad={onLoaded}
      style={style}
    />
  );
};
