const coolGreen = '#14C510';

export const lightColorTheme = {
  fillColor: 'white',
  borderColor: 'red',
  textColor: 'black',
  accentColor: 'red',
};

export const darkColorTheme = {
  fillColor: 'white',
  borderColor: '#EEF7F8',
  textColor: 'black',
  accentColor: 'red',
};

export const darkGreenTheme = {
  fillColor: 'white',
  borderColor: '#EEF7F8',
  textColor: 'black',
  accentColor: coolGreen,
};

export const nettBackgroundSrc = 'http://localhost:3000/nett-background.png';

export const menus = {
  info: 'about',
  contact: 'contact',
  shows: 'shows',
};

export const mobileBreakpoint = 500;

export const defaultAudioUrl = '/audio/2064.mp3';
