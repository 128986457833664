import { useCallback, useContext } from 'react';
import './Navigation.css';
import { SiteContext } from '../../contexts/Site/SiteContext';
import { NavigationButton } from './InfoButton';
import { menus } from '../../contexts/Site';

export const Navigation = () => {
  const {
    colorTheme: { textColor },
    currentMenu,
    setCurrentMenu,
    hoveredMenu,
    isMobileView,
  } = useContext(SiteContext);

  const onClick = useCallback(
    (menu) => {
      if (menu === currentMenu) {
        setCurrentMenu(null);
      } else {
        setCurrentMenu(menu);
      }
    },
    [currentMenu, setCurrentMenu]
  );

  return (
    <div className="navigation-container">
      <div className="navigation">
        {/* {!isMobileView && <NavigationText text={hoveredMenu} color={textColor} />} */}
        <NavigationButton onClick={onClick} menuItem={menus.shows} />
        {/* <NavigationButton onClick={onClick} menuItem={menus.contact} /> */}
        <NavigationButton onClick={onClick} menuItem={menus.info} />
      </div>
    </div>
  );
};
