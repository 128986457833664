import { useContext, useEffect, useMemo, useState } from 'react';
import './TorrentInfoText.css';
import { SiteContext } from '../../contexts/Site/SiteContext';
import { TorrentContext } from '../../contexts/Torrent';

const webtorrentLink = 'https://en.wikipedia.org/wiki/WebTorrent';

export const TorrentInfoText = () => {
  const { isSeeding, isTorrenting, numPeers, timeElapsedSeconds, isError } =
    useContext(TorrentContext);
  const {
    colorTheme: { accentColor },
    isMobileView,
  } = useContext(SiteContext);

  const peerText = (
    <span style={{ color: accentColor }}>
      {numPeers > 1 ? `${numPeers} peers` : '1 peer'}
    </span>
  );
  const webTorrentLink = (
    <a style={{ color: accentColor }} target="__blank" href={webtorrentLink}>
      WebTorrent
    </a>
  );

  const torrentSeedingMessages = [
    <>
      You are sharing this audio file with {peerText} via the {webTorrentLink}{' '}
      protocol. Keep this page open to continue "seeding".
    </>,
    <>
      This audio only exists as long as it is shared by the {webTorrentLink}{' '}
      network.
    </>,
    <>Thank you for distributing this music to {peerText}.</>,
  ];

  const torrentDownloadMessages = [
    <>Connecting...</>,
    <>
      Attempting to connecting to other devices on the {webTorrentLink}{' '}
      network... Keep this page open.
    </>,
    <>Downloading may take a moment if there are limited peers available.</>,
    <>These things take time... thank you for being patient.</>,
  ];

  const torrentErrorMessage = (
    <>
      Sorry, something went wrong. There may be no peers on the network. Try
      reloading the page
    </>
  );

  const [textIndex, setTextIndex] = useState(0);
  const [isTextVisible, setIsTextVisible] = useState(true); // Start with text visible
  const [displayedText, setDisplayedText] = useState(null);
  const [hasSeenFirstSeedMessage, setHasSeenFirstSeedMessage] = useState(false);
  // Calculate the current text based on the index
  const currentText = useMemo(
    () =>
      isError
        ? torrentErrorMessage
        : isSeeding
          ? torrentSeedingMessages[textIndex]
          : torrentDownloadMessages[textIndex],
    [textIndex, isSeeding, isError]
  );

  // Update the text index every 5 seconds
  useEffect(() => {
    const messagesList = isSeeding
      ? torrentSeedingMessages
      : torrentDownloadMessages;
    if (timeElapsedSeconds === 0) return;
    const interval = 5;
    const numItems = messagesList.length;
    const timeIndex = timeElapsedSeconds % interval;
    if (isSeeding && !hasSeenFirstSeedMessage) {
      setTextIndex(0);
      setHasSeenFirstSeedMessage(true);
      return;
    }
    if (timeIndex === 0) {
      setTextIndex((previous) => (previous + 1) % numItems);
    }
  }, [
    timeElapsedSeconds,
    isSeeding,
    hasSeenFirstSeedMessage,
    setHasSeenFirstSeedMessage,
  ]);

  // Handle fade-out and fade-in transitions when the text changes
  useEffect(() => {
    if (displayedText === null) {
      // First render: set the text instantly
      setDisplayedText(currentText);
    } else {
      // Subsequent updates: fade out, change text, then fade in
      setIsTextVisible(false); // Trigger fade-out
      const timeout = setTimeout(() => {
        setDisplayedText(currentText); // Update the text
        setIsTextVisible(true); // Trigger fade-in
      }, 500); // Match the CSS transition duration (500ms)

      return () => clearTimeout(timeout); // Cleanup timeout
    }
  }, [currentText, displayedText]);

  return (
    <div
      className={`
                ${isMobileView ? 'torrent-info-text mobile' : 'torrent-info-text'} 
                ${isTextVisible && isTorrenting ? 'visible' : 'hidden'}
            `}
    >
      {displayedText}
    </div>
  );
};
