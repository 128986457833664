import { createContext } from 'react';

const noOp = () => {};

const defaultValue = {
  currentTorrent: null,
  isTorrenting: false,
  isSeeding: false,
  initTorrent: noOp,
  audioUrl: null,
  numPeers: null,
  timeElapsedSeconds: 0,
  isError: false,
};

export const TorrentContext = createContext(defaultValue);
