import { useContext } from 'react';
import { SiteContext } from '../../contexts/Site/SiteContext';
import { ModalLink } from './ModalLink';
import bandPhoto from '../../images/nett-live.jpeg';
import { LazyImage } from '../lazy-image';
import { menus } from '../../contexts/Site';
export const NettInfo = () => {
  const bandcampLink = 'https://nettnyc.bandcamp.com';
  const spotifyLink = 'https://spotify.com';
  const {
    isMobileView,
    colorTheme: { accentColor },
    setCurrentMenu,
  } = useContext(SiteContext);

  const instagramLink = 'https://instagram.com/nett.nyc';
  const mailtoLink = 'mailto:booking@nett.nyc';
  const eventLink =
    'https://www.eventbrite.com/e/solstice-tickets-909160111367';
  const videoLink = 'https://www.youtube.com/watch?v=vB262pVvh9M';
  const imageStyle = {
    // border: `solid ${accentColor} 5px`,
    borderRadius: '10%',
    zIndex: 999,
  };

  const imageWidth = isMobileView ? '240px' : '290px';
  return (
    <div>
      <p style={{ marginBottom: '12px' }}>
        <span style={{ fontFamily: 'Karrik-Italic' }}>Nett</span> is a 5-piece
        experimental rock band based in NYC & Brooklyn.
      </p>
      <div>
        <img width={imageWidth} style={imageStyle} height='auto' src={bandPhoto} />
      </div>
      <div style={{ color: accentColor, marginTop: '5px' }}>
        <p>
          &#x279F;{' '}
          <ModalLink
            href={bandcampLink}
            color={accentColor}
            text={'Bandcamp'}
            includeIcon={false}
          />
        </p>
        <p>
          &#x279F;{' '}
          <ModalLink
            href={instagramLink}
            color={accentColor}
            text={'Instagram'}
            includeIcon={false}
          />
        </p>
        <p>
          &#x279F;{' '}
          <ModalLink
            href={mailtoLink}
            color={accentColor}
            text="Email"
            icon="mail"
            includeIcon={false}
          />
        </p>
        <p>
          &#x279F;{' '}
          <ModalLink
            href={videoLink}
            color={accentColor}
            text={'2064 video'}
            includeIcon={false}
          />
        </p>
        <p>
          &#x279F;{' '}
          <ModalLink
            color={accentColor}
            text={'Live Shows'}
            includeIcon={false}
            onClick={() => setCurrentMenu(menus.shows)}
          />
        </p>
      </div>
    </div>
  );
};
