import { useRef, useEffect } from 'react';

function animateBars(
  analyser,
  canvas,
  canvasCtx,
  dataArray,
  bufferLength,
  fillColor
) {
  analyser.getByteFrequencyData(dataArray);

  canvasCtx.fillStyle = '#000';

  const HEIGHT = canvas.height;

  var barWidth = Math.ceil(canvas.width / bufferLength) * 2.5;
  let barHeight;
  let x = 0;

  for (var i = 0; i < bufferLength; i++) {
    barHeight = (dataArray[i] / 255) * HEIGHT;
    canvasCtx.fillStyle = fillColor;
    // canvasCtx.fillRect(x, HEIGHT - barHeight, barWidth, barHeight);
    canvasCtx.fillRect(x, HEIGHT - barHeight, barWidth, barHeight);

    x += barWidth + 1;
  }
}

const WaveForm = ({ analyzerData, width, height, fillColor }) => {
  const canvasRef = useRef(null);
  const { dataArray, analyzer, bufferLength } = analyzerData;

  const draw = (dataArray, analyzer, bufferLength) => {
    const canvas = canvasRef.current;
    if (!canvas || !analyzer) return;
    const canvasCtx = canvas.getContext('2d');

    const animate = () => {
      requestAnimationFrame(animate);
      canvas.width = canvas.width;
      //   canvasCtx.translate(0,  115);
      animateBars(
        analyzer,
        canvas,
        canvasCtx,
        dataArray,
        bufferLength,
        fillColor
      );
    };

    animate();
  };

  useEffect(() => {
    draw(dataArray, analyzer, bufferLength);
  }, [dataArray, analyzer, bufferLength]);

  return (
    <canvas
      style={{
        position: 'absolute',
        bottom: '0',
        right: 0,
        zIndex: '901',
      }}
      ref={canvasRef}
      width={width}
      height={height}
    />
  );
};

export default WaveForm;
