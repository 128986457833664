import './App.css';
import './Fonts.css';
import Background from './components/background';
import Footer from './components/footer';
import Header from './components/header';
import { SiteContextProvider } from './contexts/Site';
import TorrentContextProvider from './contexts/Torrent/TorrentContextProvider';

function App() {
  return (
    <div className="App">
      <TorrentContextProvider>
        <SiteContextProvider>
          <Header />
          <Background />
          <Footer />
        </SiteContextProvider>
      </TorrentContextProvider>
    </div>
  );
}

export default App;
